const { details, projects, landingDetails } = require('../components/helpers/data');

const profileService = {
  getPersonalDetails() {
    return new Promise((res) => res(details));
  },

  getProjects() {
    return new Promise((res) => res(projects));
  },
  getLandingDetails: () => new Promise((resolve) => resolve(landingDetails))
};

export default profileService;


import React from 'react';
import { routes } from "../utils/enums"
import App from './App';
import LandingPage from './landing/LandingPage';
import ProjectsPage from "./projects/ProjectsPage";

// const appRoutes = [
//   {
//     path: routes.MAIN,
//     element: <LandingPage />
//   },
//   {
//     path: routes.MAIN,
//     element: <App />,
//     children: [
//       {
//         path: routes.PROJECTS.substring(1),
//         element: <ProjectsPage />
//       }
//     ]
//   }
// ];

const appRoutes = [
  {
    path: routes.MAIN,
    element: <App />,
    children: [
      {
        path: "",
        element: <ProjectsPage />
      }
    ]
  }
];

export default appRoutes;
import React from 'react'
import PropTypes from 'prop-types'
import CardExternalLinkContainer from './CardExternalLinkContainer';

CardTechStack.propTypes = {
  techStack: PropTypes.array
}

function CardTechStack({ techStack, link }) {
  const getContent = () => (
    <>
      <div className="source-code__main">
          {techStack.length > 0 && (
            <ul className="highlights">
              {techStack.map((stack, i) => (
                <li key={i}>{stack}</li>
              ))}
            </ul>
          )}
        </div>
      {link ? <CardExternalLinkContainer /> : null}
    </>
  );

  return link ? (
    <a href={link} target="_blank" className="source-code details-section">
      {getContent()}
    </a>
  ) : (
    <div className="source-code details-section no-link">
      {getContent()}
    </div>
  )
}

export default CardTechStack

import React from 'react'
import PropTypes from 'prop-types'
import Card from '../common/card/Card';
import FeaturedProject from './FeaturedProject';

Projects.propTypes = {
  projects: PropTypes.array
};

Projects.defaultProps = {
  projects: []
}

function Projects({ projects }) {
  const featuredProjects = projects.filter(p => p.isFeatured);
  const otherProjects = projects.filter(p => !p.isFeatured);
  return (
    <div className="projects">
      {featuredProjects.length && (
        <div className='featured-section'>
          <h2>Featured Project{featuredProjects.length > 1 ? 's' : ''}</h2>
          {featuredProjects.map(p => <FeaturedProject project={p} key={p.title} />)}
        </div>
      )}
      <div className='other-projects-section'>
        <h2>Past Projects</h2>
        <p className='disclaimer'><span className='bold'>**Disclaimer**:</span> Project structures and lack of unit tests are not indicative of how I would actually create production ready commercial code.</p>
        <div className="card-list">
          {otherProjects.map(project => (
            <Card key={project.title} {...project} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Projects

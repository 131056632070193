import './card.scss';
import React from 'react';
import { string, array } from 'prop-types';
import CardTechStack from './CardTechStack';
import CardMainContent from './CardMainContent';

Card.propTypes = {
  title: string,
  imgSrc: string,
  website: string,
  github: string,
  description: string,
  techStack: array,
  className: string
};

function Card({ title, imgSrc, website, github, description, techStack, className }) {
  return (
    <div className={`card-container ${className}`}>
      <div className="card">
        <CardMainContent
          imgSrc={imgSrc}
          title={title}
          website={website}
          description={description}
        />
        <CardTechStack
          link={github}
          techStack={techStack}
        />
      </div>
    </div>
  );
}

export default Card;

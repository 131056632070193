import './primary-btn.scss';
import React from 'react'
import PropTypes from 'prop-types'

PrimaryBtn.propTypes = {
  onClick: PropTypes.func,
  extraRootClasses: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

PrimaryBtn.defaultProps = {
  extraRootClasses: ''
}

function PrimaryBtn({ onClick, extraRootClasses, children }) {
  return (
    <button className={`primary-btn ${extraRootClasses}`} onClick={onClick}>{children}</button>
  )
}

export default PrimaryBtn

import React, { useState, useEffect, createContext } from 'react';
import { throttle } from 'throttle-debounce';

const getViewportDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight
});

export const ViewportContext = createContext(getViewportDimensions());

/**
 * Should only be used once per rendered page
 */
const ViewportProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState(getViewportDimensions());

  const handleWindowResize = throttle(100, () => setDimensions(getViewportDimensions()));

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return <ViewportContext.Provider value={dimensions}>{children}</ViewportContext.Provider>;
};

export default ViewportProvider;

import React from 'react'
import PropTypes from 'prop-types'
import CardExternalLinkContainer from './CardExternalLinkContainer';

CardMainContent.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  website: PropTypes.string
};

function CardMainContent({ imgSrc, title, description, website }) {
  const getContent = () => (
    <>
      <div className='project-image-container'>
        <img
          className="project-image"
          src={imgSrc}
          alt="Project Image"
          target="_blank"
          loading="lazy"
        />
      </div>
      <div className="description details-section">
        <div className="description__main">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        {website ? <CardExternalLinkContainer /> : null}
      </div>
    </>
  );

  return website ? (
    <a href={website} target="_blank" className="project-link">
      {getContent()}
    </a>
  ) : (
    <div className="project-link no-link">
      {getContent()}
    </div>
  )
}

export default CardMainContent

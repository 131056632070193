import './featured-project.scss';
import React from 'react'
import PropTypes from 'prop-types';
import PrimaryBtn from '../common/PrimaryBtn';

FeaturedProject.propTypes = {
  project: PropTypes.object
};

function FeaturedProject({ project }) {
  const openWebsite = () => {
    window.open(project.website, '_blank').focus();
  };

  return (
    <div className='featured-project'>
      <div className='text-content'>
        <h3>{project.title}</h3>
        <p className='description'>{project.description}</p>
        <div className="tech-stack">
          <h4>Technology Stack</h4>
          {project.techStack.length > 0 && (
            <ul className="highlights">
              {project.techStack.map((stack, i) => (
                <li key={i}>{stack}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className='website-display-section'>
        <img
          className="project-image"
          src={project.imgSrc}
          alt="Project Image"
          target="_blank"
          loading="lazy"
        />
        <PrimaryBtn onClick={openWebsite}>View website</PrimaryBtn>
      </div>
    </div>
  )
}

export default FeaturedProject

import React from 'react';
import Header from './common/Header';
import './app.scss';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router';

class App extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Outlet />
        <ToastContainer />
      </>
    );
  }
}

export default App;

import React from 'react';
import { FiExternalLink } from 'react-icons/fi';

function CardExternalLinkContainer() {
  return (
    <div className="card-external-link-container">
      <FiExternalLink />
    </div>
  );
}

export default CardExternalLinkContainer;

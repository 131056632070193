import './projects-page.scss';
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import profileService from '../../services/profileService';
import Projects from './Projects'
import { titles } from '../helpers/data';
import wanderlousImg from '../../../public/images/wanderlous-landing-page.png';
import whoDrinksImg from '../../../public/images/who-drinks-logo.jpg';
import whoDrinksApiImg from '../../../public/images/cards.jpg';
import recauraImg from '../../../public/images/digital-hand.jpg';
import ptfilesImg from '../../../public/images/medical-tech.jpg';
import gameonImg from '../../../public/images/bball-game.jpg';
import gameonApiImg from '../../../public/images/bball-words.jpg';
import fitbitImg from '../../../public/images/fitbit-simple-slick-minimal.png';

function ProjectsPage() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const getProjectImage = (title) => {
    switch (title) {
      case titles.RECAURA:
        return recauraImg;
      case titles.WHO_DRINKS:
        return whoDrinksImg;
      case titles.WHO_DRINKS_API:
        return whoDrinksApiImg;
      case titles.WANDERLOUS:
        return wanderlousImg;
      case titles.FITBIT_SLICK_MINIMAL:
        return fitbitImg;
      case titles.PT_FILES:
        return ptfilesImg;
      case titles.GAME_ON:
        return gameonImg;
      case titles.GAME_ON_API:
        return gameonApiImg;
      default:
        return '';
    }
  }

  const init = async () => {
    try {
      const projects = await profileService.getProjects();
      projects.forEach((project) => {
        project.imgSrc = getProjectImage(project.title);
        project.className = project.title.replaceAll(' ', '-').toLowerCase()
      });
      setProjects(projects);
    } catch (e) {
      toast.error('Unable to get project data', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true
      });
    }
  }

  return (
    <Projects projects={projects} />
  )
}

export default ProjectsPage

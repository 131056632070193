import './header.scss';
import React, { useEffect, useState } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import { toast } from 'react-toastify';
import "!style-loader!css-loader!react-toastify/dist/ReactToastify.css"
import profileService from '../../services/profileService';
import LinkedInIcon from '../../../public/images/LI-In-Bug.png';
import GitHubIcon from '../../../public/images/GitHub-Mark-64px.png';
import { Link } from 'react-router-dom';
import { routes } from '../../utils/enums';
import PrimaryBtn from './PrimaryBtn';
import ViewportProvider from '../../globals/ViewportProvider';
import useViewport from '../../hooks/useViewport';

const Header = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [links, setLinks] = useState({
        linkedin: '',
        github: '',
        email: ''
    });

    const { width } = useViewport();

    useEffect(() => {
        loadPersonalDetails();
    }, []);

    const loadPersonalDetails = async () => {
        try {
            const details = await profileService.getPersonalDetails();
            if (!details) {
                displayFetchDetailsToast();
                return;
            }
            setTitle(details.title);
            setSubtitle(details.subtitle);
            const { linkedin, github, email } = details;
            setLinks({ linkedin, github, email });
        } catch (e) {
            displayFetchDetailsToast()
        }
    };

    const displayFetchDetailsToast = () => {
        toast.error('Unable to get data', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true
        })
    }

    const handleEmailClick = () => {
        if (!document.queryCommandSupported('copy')) {
            return alert('Unable to copy')
        }

        // Create and append element to unseen part of page to copy and then remove again
        var el = document.createElement('textarea');
        el.value = links.email;
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        toast.info('Copied email to clipboard', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true
        });
    };

    const getEnvelopeSize = () => {
        if (width >= 1080) return "1.4em";
        if (width >= 768) return "2rem";
        return "2.4rem";
    }

    return (
      <header>
        <div className="page-header">
          <div className="page-header__headings">
            {/* <Link to={routes.MAIN}> */}
                <h1>{title ?? 'Jeffrey Huang'}</h1>
            {/* </Link> */}
            <p>{subtitle ?? 'Senior Software Engineer'}</p>
          </div>
          <div className="page-header__clickables">
            <div className="page-header__clickables__top-row">
              <div className="email-copy-section">
                <FaEnvelope size={getEnvelopeSize()} />
                <p className="label">{links.email}</p>
                <PrimaryBtn onClick={handleEmailClick}>Copy</PrimaryBtn>
              </div>
            </div>
            <div className="page-header__clickables__logos">
              <a
                href={links.linkedin}
                target="_blank"
                // data-tip="Open LinkedIn profile in a new browser tab."
              >
                <img id="linkedin-logo" className="logo" src={LinkedInIcon} alt="LinkedIn Logo" />
              </a>
              <a
                href={links.github}
                target="_blank"
                // data-tip="Open GitHub profile in a new browser tab."
              >
                <img id="github-mark" className="logo" src={GitHubIcon} alt="GitHub Mark" />
              </a>
            </div>
          </div>
          <ReactTooltip class='tooltip' place="top" type="info" effect="float" />
        </div>
      </header>
    );
};

const HeaderWrapper = () => (
    <ViewportProvider>
        <Header />
    </ViewportProvider>
);

export default HeaderWrapper;
const details = Object.freeze({
  title: 'Jeffrey Huang',
  subtitle: 'Senior Software Engineer',
  linkedin: 'https://linkedin.com/in/jeff-v-h',
  github: 'https://github.com/jeff-v-h',
  email: 'jh0791@gmail.com'
});

const landingDetails = Object.freeze({
  title: 'Jeffrey Huang',
  // initialMsg: 'Welcome to my personal website!',
  personalDescription: `A full stack software engineer interested in using technology to solve problems that lead to real world value at scale.`
})

const titles = Object.freeze({
  RECAURA: 'Recaura',
  WHO_DRINKS: 'Who Drinks',
  WHO_DRINKS_API: 'Who Drinks API',
  FITBIT_SLICK_MINIMAL: 'Fitbit Simple Slick Minimal Clockface',
  PT_FILES: 'PT Files',
  WANDERLOUS: 'Wanderlous',
  GAME_ON: 'Game On Client',
  GAME_ON_API: 'Game On API'
});

const projects = Object.freeze([
  {
    title: titles.WANDERLOUS,
    website: 'https://www.wanderlous.com',
    github: '',
    description: 'Travel planning web application. This is a more fully featured project relative to the other ones created in the past. From a technical perspective it includes features such as authentication, authorisation, rate limiting, third party API integrations, messaging queues, server side events, domain driven design, clean architecture and the repository pattern.',
    techDescription: ['Third party integrations for authentication, geolocation, a dynamic map and emailing', 'redis based messaging queue', 'transition from three-tier to clean architecture', 'Domain-driven design', 'repository pattern'],
    techStack: ['TypeScript', 'React', 'SASS', 'Node.js', 'GraphQL', 'MySQL', 'Redis', 'Docker', 'Azure'],
    isFeatured: true,
    year: 2023
  },
  {
    title: titles.WHO_DRINKS,
    website: '',
    github: 'https://github.com/jeff-v-h/WhoDrinks',
    description: 'A drinking based card game. This was primarily developed to get some exposure into mobile application development. It was deployed onto Google\'s Play Store for a short time.',
    techDescription: ['Mobile app development'],
    techStack: ['React Native', 'Redux Toolkit'],
    isFeatured: false,
    year: 2021
  },
  {
    title: titles.WHO_DRINKS_API,
    website: '',
    github: 'https://github.com/jeff-v-h/WhoDrinksApi',
    description: 'An API for the Who Drinks mobile application. Working on this provided insight into changes from .NET Core 3 and some experience with various AWS services such as ECS.',
    techDescription: [''],
    techStack: ['C# (.NET 5)', 'MongoDB', 'Docker', 'AWS'],
    isFeatured: false,
    year: 2021
  },
  {
    title: titles.FITBIT_SLICK_MINIMAL,
    website: 'https://gallery.fitbit.com/details/415d17a5-bf80-48d8-93b0-895f0f7d7c88',
    github: 'https://github.com/jeff-v-h/FitbitSimpleSlickMinimalClockface',
    description: 'A minimal clockface for the fitbit smart watch. There were no clock faces that fit my criteria, so I built my own.',
    techDescription: [''],
    techStack: ['JavaScript', 'CSS', 'Fitbit SDK'],
    isFeatured: false,
    year: 2021
  },
  {
    title: titles.PT_FILES,
    website: '',
    github: 'https://github.com/jeff-v-h/pt-files',
    description: 'Simplified physiotherapy record management web application using clean architecture.',
    techDescription: ['Clean architecture'],
    techStack: [
      'TypeScript',
      'React',
      'Redux',
      'C# (.NET Core 3)',
      'SQL Server',
      'Docker'
    ],
    isFeatured: false,
    year: 2020
  },
  {
    title: titles.RECAURA,
    website: '',
    github: 'https://github.com/jeff-v-h/Recaura',
    description: 'Note-taking application for health professionals. This was an initial dive into using Docker and setting up CI/CD to deploy on AWS.',
    techDescription: ['JWT auth', 'CI/CD'],
    techStack: ['TypeScript', 'React', 'Redux', 'Node.js', 'MongoDB', 'Docker', 'AWS'],
    isFeatured: false,
    year: 2020
  },
  {
    title: titles.GAME_ON,
    website: '',
    github: 'https://github.com/jeff-v-h/game-on',
    description: 'Client UI of a web application to view recent and upcoming sports tournaments and matches. This helped upskill in using TypeScript with React.',
    techDescription: ['Three-tier architecture'],
    techStack: ['TypeScript', 'React', 'Redux', 'SASS'],
    isFeatured: false,
    year: 2020
  },
  {
    title: titles.GAME_ON_API,
    website: '',
    github: 'https://github.com/jeff-v-h/game-on-api',
    description: 'API to obtain data for certain sports and e-sports. This was to learn more about NoSQL.',
    techDescription: ['three-tier architecture'],
    techStack: ['C# (.NET Core 3)', 'MongoDB'],
    isFeatured: false,
    year: 2020
  }
]);

module.exports = {
  details,
  projects,
  titles,
  landingDetails
};
